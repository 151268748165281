import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

const WhoWeAre = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [direction, setDirection] = useState(0);

  const aboutUsData = {
    title: "Transforming Ideas into Reality",
    mission: {
      title: "Our Mission",
      content:
        "Our mission at Nebula Technology is to provide innovative IT solutions that drive efficiency, scalability, and success. We aim to build long-term partnerships and help clients achieve their goals in a rapidly evolving digital world.",
    },
    vision: {
      title: "Our Vision",
      content:
        "Our vision is to become a global leader in IT services and product innovation, shaping the future of technology with creativity, integrity, and a customer-centric approach. We strive to inspire transformation and unlock potential, creating a world where businesses and technology seamlessly thrive together.",
    },
  };

  const data = [aboutUsData.mission, aboutUsData.vision];

  const handleDotClick = (index) => {
    setDirection(index > activeIndex ? 1 : -1);
    setActiveIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setDirection(1);
      setActiveIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [data.length]);

  const carouselVariants = {
    // enter: (direction) => ({
    //   x: direction > 0 ? 300 : -300,
    //   opacity: 0,
    // }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        type: "tween",
      },
    },
    exit: (direction) => ({
      zIndex: 0,
      x: direction < 0 ? 300 : -300,
      opacity: 0,
      transition: {
        duration: 0.5,
        type: "tween",
      },
    }),
  };

  return (
    <div className="py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h3 className="text-3xl text-center text-[#fb6962] relative mb-16 font-['Raleway'] font-bold">
          WHO WE ARE
        </h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 relative">
          {/* Left Section */}
          <div className="flex flex-col">
            <h1 className="mt-8 text-3xl font-bold text-gray-900 mb-3">
              {aboutUsData.title}
            </h1>
            <p className="text-gray-700 mb-4 leading-relaxed indent-14">
              Welcome to Nebula technology, your number one source for all
              things in Software Solutions. We're dedicated to giving you the
              very best of Software Solutions, with a focus on Java, Android,
              Spring Hibernate, IOS, PHP, Digital Marketing etc. We have
              expertise in different domains and we are passionate about
              upgrading ourselves in new technologies as well. Our long-term
              goal is to provide end-to-end service to our clients and make them
              satisfied with our service. We are working with different
              technologies to implement clients' innovative ideas in real-time
              environments. We hope you enjoy our services and products as much
              as we enjoy offering them to you. If you have any questions or
              comments, please don't hesitate to contact us.
            </p>

            <div className="relative h-60 mb-8">
              {/* Carousel with content */}
              <AnimatePresence initial={false} mode="wait">
                <motion.div
                  key={activeIndex}
                  custom={direction}
                  variants={carouselVariants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  className="absolute w-full"
                >
                  <div className="p-8 bg-[#1e293b] rounded-lg shadow-lg ">
                    <h3 className="text-2xl text-white font-bold mb-4">
                      {data[activeIndex].title}
                    </h3>
                    <p className="text-white">{data[activeIndex].content}</p>
                  </div>
                </motion.div>
              </AnimatePresence>
            </div>
            {/* Dots placed outside the scrollable area */}
            <div className="flex justify-center mt-5 space-x-2">
              {data.map((_, index) => (
                <button
                  key={index}
                  className={`w-3 h-3 rounded-full transition-colors duration-300 ${
                    activeIndex === index
                      ? "bg-gray-800"
                      : "bg-gray-400 hover:bg-gray-600"
                  }`}
                  onClick={() => handleDotClick(index)}
                ></button>
              ))}
            </div>
          </div>

          {/* Right Section */}
          <div className="relative h-full w-full">
            <div className="top-0 left-0 w-full h-full flex items-center justify-center">
              <div className="relative w-full lg:w-[600px] max-w-4xl">
                <img
                  src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                  alt="Presentation"
                  className="rounded-lg shadow-lg w-full h-auto"
                />
                <div
                  className="absolute bg-gray-900 text-white p-6 rounded-lg shadow-md"
                  style={{
                    top: "80%",
                    left: "80%",
                    transform: "translate(-50%, -50%) translateY(50%)", // Adjust placement
                    zIndex: 10,
                  }}
                >
                  
                  <p className="text-2xl font-bold">Efficiency.</p>
                  <p className="text-sm"> Growth. Success.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;
