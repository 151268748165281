import React, { useState, useEffect } from 'react';
import { Menu, X, LogIn, ChevronDown } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
 import '../Styles/logo.css';
import icon from '../assets/lngico/icon.png';


const navigation = [
    { name: 'HOME', href: '/' },
    { name: 'ABOUT', href: '/about' },
    {
        name: 'SERVICES',
        href: '/services',
        dropdownItems: [
            { name: 'Erp Solutions', href: '/services/erp-solutions' },
            { name: 'Client-Based Applications', href: '/services/client-based-applications' },
            { name: 'Product Development', href: '/services/product-development' },
            { name: 'HR Solutions', href: '/services/hr-solutions' },
            { name: 'Digital Marketing', href: '/services/digital-marketing' },

        ]
    },
    { name: 'OUR PRODUCTS', href: '/ourproduct' },

    { name: 'CAREERS', href: '/careers' },
    { name: 'CONTACT US', href: '/contact' },
];

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 60);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <nav className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-white shadow-lg h-24' : 'bg-transparent h-30'}`}>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 h-full">
                <div className="relative flex h-full items-center justify-between">
                    {/* Mobile menu button */}
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className={`p-2 rounded-md ${isScrolled ? 'text-gray-800' : 'text-white'}`}
                        >
                            <span className="sr-only">Open main menu</span>
                            {isOpen ? (
                                <X className="block h-6 w-6" />
                            ) : (
                                <Menu className="block h-6 w-6" />
                            )}
                        </button>
                    </div>

                    {/* Logo and Title */}
                    <div className="flex-1 flex items-center justify-center sm:justify-start">
                        <Link to="/" className="block">
                            <div className="flex flex-shrink-0 items-center">
                                <div className="flex flex-col">
                                    <h1 className="flex items-center text-xl font-bold mt-3 mx-3">
                                        <span style={{ color: '#b71c1c', fontSize: 46 }}>n</span>{" "}
                                        <img
                                            alt="Nebula logo"
                                            src={icon}
                                            className="w-5 h-5 logo mt-2"
                                        />{" "}
                                        <span style={{ color: '#b71c1c', fontSize: 46 }}>bula</span>
                                    </h1>
                                    <div className="flex flex-col items-start mt-1">
                                        <span className="custom-background text-md font-bold">
                                            TECHNOLOGY
                                        </span>
                                        <span className="text-md italic text-blue-600 text-center font-cursive mx-3">
                                            where creation happens...
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Link>

                        {/* Desktop navigation */}
                        <div className="hidden sm:ml-auto sm:flex sm:items-center">
                            <div className="flex items-center space-x-8">
                                {navigation.map((item) => (
                                    <div key={item.name}>
                                        {item.dropdownItems ? (
                                            <div className="relative group">
                                                <button
                                                    className={`flex items-center px-1 py-2 text-sm font-light transition-colors duration-200
                                                        ${isScrolled
                                                            ? 'text-gray-800 hover:text-[#fb6962]'
                                                            : 'text-white hover:text-[#fb6962]'
                                                        }
                                                        ${location.pathname.startsWith(item.href)
                                                            ? 'after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-[#fb6962]'
                                                            : ''
                                                        }
                                                    `}
                                                >
                                                    {item.name}
                                                    <ChevronDown className="ml-1 w-4 h-4" />
                                                </button>

                                                {/* Dropdown menu */}
                                                <div className="absolute hidden group-hover:block left-1/2 transform -translate-x-1/2 pt-2 w-48">
                                                    <div className="bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5">
                                                        <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 w-2 h-2 bg-white rotate-45"></div>
                                                        <div className="relative bg-white rounded-md">
                                                            {item.dropdownItems.map((dropdownItem) => (
                                                                <Link
                                                                    key={dropdownItem.name}
                                                                    to={dropdownItem.href}
                                                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-[#fb6962]"
                                                                >
                                                                    {dropdownItem.name}
                                                                </Link>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <Link
                                                to={item.href}
                                                className={`relative px-1 py-2 text-sm font-light transition-colors duration-200
                                                    ${isScrolled
                                                        ? 'text-gray-800 hover:text-[#fb6962]'
                                                        : 'text-white hover:text-[#fb6962]'
                                                    }
                                                    ${location.pathname === item.href
                                                        ? 'after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-[#fb6962]'
                                                        : ''
                                                    }
                                                `}
                                            >
                                                {item.name}
                                            </Link>
                                        )}
                                    </div>
                                ))}

                                {/* Sign In Button */}
                                <Link
                                    to="/signin"
                                    className={`flex items-center px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 
                                        ${isScrolled
                                            ? 'text-gray-800 hover:text-[#fb6962] border border-gray-300 hover:border-[#fb6962]'
                                            : 'text-white hover:text-[#fb6962] border border-white hover:border-[#fb6962]'
                                        }`}
                                >
                                    <LogIn className="w-4 h-4 mr-2" />
                                    Sign In
                                </Link>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            {/* Mobile menu */}
            <div className={`sm:hidden transition-all duration-300 ${isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'} overflow-hidden bg-white/95 backdrop-blur-md`}>
                <div className="px-4 pt-2 pb-3 space-y-1">
                    {navigation.map((item) => (
                        <div key={item.name}>
                            {item.dropdownItems ? (
                                <div className="space-y-1">
                                    <button
                                        onClick={() => setDropdownOpen(!dropdownOpen)}
                                        className={`flex items-center justify-between w-full px-3 py-2 text-base font-medium text-gray-800 hover:text-[#fb6962] hover:bg-gray-50`}
                                    >
                                        {item.name}
                                        <ChevronDown className={`w-4 h-4 transition-transform ${dropdownOpen ? 'rotate-180' : ''}`} />
                                    </button>
                                    <div className={`space-y-1 pl-4 ${dropdownOpen ? 'block' : 'hidden'}`}>
                                        {item.dropdownItems.map((dropdownItem) => (
                                            <Link
                                                key={dropdownItem.name}
                                                to={dropdownItem.href}
                                                className="block px-3 py-2 text-sm text-gray-600 hover:text-[#fb6962] hover:bg-gray-50"
                                                onClick={() => {
                                                    setIsOpen(false);
                                                    setDropdownOpen(false);
                                                }}
                                            >
                                                {dropdownItem.name}
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <Link
                                    to={item.href}
                                    className={`block px-3 py-2 text-base font-medium transition-colors duration-200
                                        ${location.pathname === item.href
                                            ? 'text-[#fb6962] border-l-4 border-[#fb6962] bg-gray-50'
                                            : 'text-gray-800 hover:text-[#fb6962] hover:bg-gray-50'
                                        }
                                    `}
                                    onClick={() => setIsOpen(false)}
                                >
                                    {item.name}
                                </Link>
                            )}
                        </div>
                    ))}

                    {/* Mobile Sign In Button */}
                    <div className="flex flex-col space-y-2 px-3 py-4 border-t border-gray-200">
                        <Link
                            to="/signin"
                            className="flex items-center justify-center px-4 py-2 rounded-lg text-sm font-medium text-gray-800 border border-gray-300 hover:text-[#fb6962] hover:border-[#fb6962] transition-all duration-200"
                            onClick={() => setIsOpen(false)}
                        >
                            <LogIn className="w-4 h-4 mr-2" />
                            Sign In
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;