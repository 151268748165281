import { useState } from "react";
import { MapPin, Phone, Mail, Rocket, Users, Zap } from "lucide-react";
import { Link } from 'react-router-dom';
import landingimg from '../assets/img/landingimg.jpg';
import careerGif from '../assets/video1.mp4';

function Careers() {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const toggleForm = () => setIsFormOpen(!isFormOpen);

  return (
    <>
      <div className="relative w-full h-[600px] overflow-hidden">
        <img
          src={landingimg}
          alt="Contact page"
          className="absolute w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center">
          <h1 className="text-3xl font-semibold text-white mb-4">Careers</h1>
          <div className="flex items-center gap-2 text-white">
            <Link to="/" className="hover:text-gray-200">
              Home
            </Link>
            <span className="text-lg">›</span>
            <span>Careers</span>
          </div>
        </div>
      </div>
      <div className="min-h-screen bg-gray-100">
        <div className="bg-white py-20">
          <div className="container mx-auto">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="md:w-1/2 md:mr-14">
                <h2 className="text-4xl font-semibold mb-6">
                  Shape the Future of Technology with Us!
                </h2>
                <p className="text-gray-500 mb-6 leading-relaxed">
                  <span className="text-gray-500 font-bold">At Nebula</span>,We are a dynamic IT company specializing in innovative web solutions and delivering tailored, client-focused projects. With a passion for technology and excellence, our mission is to empower businesses with cutting-edge tools and unmatched expertise.
                </p>
                <p className="text-gray-500 mb-6 leading-relaxed">
                At Nebula, we foster a collaborative, growth-oriented work environment where creativity and innovation thrive. Join us to be part of a forward-thinking team that's shaping the future of technology!
                </p>
                <div className="flex items-start gap-4 mb-4">
                  <span className="flex-shrink-0 text-[#fb6962]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </span>
                  <div>
                    <p className="text-gray-500">Delivering high-quality services to diverse clients</p>
                  </div>
                </div>
                <div className="flex items-start gap-4 mb-4">
                  <span className="flex-shrink-0 text-[#fb6962]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </span>
                  <div>
                    <p className="text-gray-500">
                      Supporting clients in transforming ideas into reality</p>
                  </div>
                </div>
                <div className="flex items-start gap-4 mb-8">
                  <span className="flex-shrink-0 text-[#fb6962]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </span>
                  <div>
                    <p className="text-gray-500">Expertise in end-to-end software development</p>
                  </div>
                </div>
                <Link to="/services" className="bg-[#fb6962] hover:bg-[#fa3930] text-white py-3 px-6 rounded-md">
                  View Our Services
                </Link>
              </div>
              <div className="md:w-1/2 mt-16 md:mt-0">
                <video
                  className="w-full max-w-lg h-auto rounded-md shadow-lg"
                  autoPlay
                  muted
                  loop
                  playsInline
                >
                  <source src={careerGif} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
        <section className="py-16 bg-gray-900 relative">
          <div className="container mx-auto">
            <h2 className="text-3xl font-bold text-white mb-8 text-center">Join Our Team!!</h2>
            <p className="text-center text-white mb-8">
              We're always looking for talented individuals to join our growing
              team. If you're passionate about technology and want to make a
              difference, we want to hear from you!
            </p>
            <div className="text-center">
              <button
                className="px-6 py-3 bg-[#fb6962] text-white font-semibold rounded hover:bg-[#fa3930] transition-colors"
                onClick={toggleForm}
              >
                Open Application Form
              </button>
            </div>

            {isFormOpen && (
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-lg relative">
                  <button
                    onClick={toggleForm}
                    className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 transition-colors"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                  <h3 className="text-2xl font-bold mb-6 text-center">Application Form</h3>
                  <form className="space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-700">
                          Name
                        </label>
                        <input
                          id="name"
                          type="text"
                          className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-all"
                          placeholder="John Doe"
                          required
                        />
                      </div>
                      <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-700">
                          Email
                        </label>
                        <input
                          id="email"
                          type="email"
                          className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-all"
                          placeholder="john@example.com"
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-700">
                        Phone
                      </label>
                      <input
                        id="phone"
                        type="tel"
                        className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-all"
                        placeholder="+1 (555) 000-0000"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="resume" className="block mb-2 text-sm font-medium text-gray-700">
                        Resume
                      </label>
                      <input
                        id="resume"
                        type="file"
                        className="w-full border border-gray-300 rounded-md p-2 file:mr-4 file:rounded-md file:border-0 file:bg-blue-50 file:px-4 file:py-2 file:text-[#fb6962] hover:file:bg-blue-100"
                        accept=".pdf,.doc,.docx"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="cover-letter" className="block mb-2 text-sm font-medium text-gray-700">
                        Cover Letter
                      </label>
                      <textarea
                        id="cover-letter"
                        className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-blue-500 focus:outline-none transition-all"
                        placeholder="Tell us why you're interested in joining Nebula Technology"
                        rows={4}
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className="w-full px-6 py-3 bg-[#fb6962] text-white font-semibold rounded-md hover:bg-[#fa3930] transition-colors"
                    >
                      Submit Application
                    </button>
                  </form>
                </div>
              </div>
            )}
          </div>
        </section>

        <div className="mt-12 text-center px-4 mb-5">
          <h3 className="text-2xl font-semibold text-gray-800 mb-8">
            Why Join Nebula?
          </h3>
          <div className="grid md:grid-cols-3 gap-6 max-w-4xl mx-auto">
            <div className="bg-white p-6 rounded-lg shadow-md transform transition-all duration-300 hover:scale-105 hover:shadow-xl group">
              <div className="bg-[#fb6962] text-white w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4 group-hover:rotate-6 transition-transform">
                <Rocket className="w-8 h-8" />
              </div>
              <h4 className="text-lg font-bold mb-2 group-hover:text-[#fb6962] transition-colors">
                Growth Opportunities
              </h4>
              <p className="text-gray-600 group-hover:text-gray-800 transition-colors">
                Continuous learning and professional development that propels your career forward.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md transform transition-all duration-300 hover:scale-105 hover:shadow-xl group">
              <div className="bg-[#fb6962] text-white w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4 group-hover:rotate-6 transition-transform">
                <Users className="w-8 h-8" />
              </div>
              <h4 className="text-lg font-bold mb-2 group-hover:text-[#fb6962] transition-colors">
                Collaborative Culture
              </h4>
              <p className="text-gray-600 group-hover:text-gray-800 transition-colors">
                Work alongside talented professionals in a supportive and innovative environment.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md transform transition-all duration-300 hover:scale-105 hover:shadow-xl group">
              <div className="bg-[#fb6962] text-white w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-4 group-hover:rotate-6 transition-transform">
                <Zap className="w-8 h-8" />
              </div>
              <h4 className="text-lg font-bold mb-2 group-hover:text-[#fb6962] transition-colors">
                Innovative Projects
              </h4>
              <p className="text-gray-600 group-hover:text-gray-800 transition-colors">
                Engage with cutting-edge technology and challenging projects that push boundaries.
              </p>
            </div>
          </div>
        </div>

        <section className="py-16 bg-[#eff6ff]">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-12 text-center text-gray-800">Visit Us</h2>
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div>
                <div className="space-y-6">
                  <div className="flex items-start">
                    <MapPin className="w-6 h-6 text-[#fb6962] mr-4 flex-shrink-0 mt-1" />
                    <div>
                      <h3 className="font-semibold text-gray-800">Address</h3>
                      <p className="text-gray-600">
                        CTS No. 349, Flat No. T-4, 3rd floor, Above Anand Asha Restaurant Bar,
                        Opposite Durga Mata Mandir, Near Ideal Metro Station,
                        Paud Road, Kothrud, Pune-411038.
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <Phone className="w-6 h-6 text-[#fb6962] mr-4 flex-shrink-0 mt-1" />
                    <div>
                      <h3 className="font-semibold text-gray-800">Phone</h3>
                      <p className="text-gray-600">+91 8668768612 / 7507316272</p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <Mail className="w-6 h-6 text-[#fb6962] mr-4 flex-shrink-0 mt-1" />
                    <div>
                      <h3 className="font-semibold text-gray-800">Email</h3>
                      <p className="text-gray-600">hr@nebula-technology.com</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-lg overflow-hidden shadow-lg">
                <iframe
                  title="nebula_technology"
                  className="w-full h-[400px]"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15133.991230574691!2d73.8231593!3d18.5063932!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x12d81c9f02199c77!2sNebula%20Technology!5e0!3m2!1sen!2sin!4v1646203735297!5m2!1sen!2sin"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </section>

      </div>
    </>
  );
}

export default Careers;