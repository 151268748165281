import { useState } from "react";
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from "react-icons/fa";
import { Mail, Phone, MapPin } from "lucide-react";
import { Link } from "react-router-dom";
import "../Styles/logo.css";
import icon from "../assets/lngico/icon.png";

export default function Footer() {
  const [email, setEmail] = useState("");

  const handleSignUp = (e) => {
    e.preventDefault();
    console.log("Sign up with:", email);
    setEmail("");
  };

  return (
    <footer className="bg-black text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 ">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="flex flex-col items-center sm:items-start text-center sm:text-left">
            <h1 className="flex items-center text-xl font-bold mt-3 mx-3">
              <span style={{ color: "#b71c1c", fontSize: 46 }}>n</span>
              <img alt="Nebula logo" src={icon} className="w-5 h-5 logo mt-2" />
              <span style={{ color: "#b71c1c", fontSize: 46 }}>bula</span>
            </h1>
            <div className="flex flex-col items-center sm:items-start mt-1">
              <span className="custom-background text-md font-bold">
                TECHNOLOGY
              </span>
              <span className="text-sm italic text-blue-600 text-center mx-3 font-cursive">
                where creation happens...
              </span>
            </div>
            <p className="text-sm lg:text-base mb-4">
              Innovating for a brighter future
            </p>
            <div className="flex flex-wrap justify-center sm:justify-start space-x-4 mt-2">
              <Link to="#" className="hover:text-[#fb6962] transition-colors">
                <FaFacebook className="h-6 w-6" />
              </Link>
              <Link to="#" className="hover:text-[#fb6962] transition-colors">
                <FaTwitter className="h-6 w-6" />
              </Link>
              <Link to="#" className="hover:text-[#fb6962] transition-colors">
                <FaLinkedin className="h-6 w-6" />
              </Link>
              <Link to="#" className="hover:text-[#fb6962] transition-colors">
                <FaInstagram className="h-6 w-6" />
              </Link>
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-center  ">
              Quick Links
            </h3>
            <ul className="grid grid-cols-2 gap-4 text-sm lg:text-base text-center ">
              <li>
                <Link to="/" className="hover:text-[#fb6962] transition-colors  hover:border-b-2 border-[#fb6962]">
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="hover:text-[#fb6962] transition-colors  hover:border-b-2 border-[#fb6962]"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  className="hover:text-[#fb6962] transition-colors  hover:border-b-2 border-[#fb6962]"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="hover:text-[#fb6962] transition-colors  hover:border-b-2 border-[#fb6962]"
                >
                  Contact
                </Link>
              </li>
              <li>
                <Link
                  to="/careers"
                  className="hover:text-[#fb6962] transition-colors hover:border-b-2 border-[#fb6962]"
                >
                  Careers
                </Link>
                </li>
                <li>
                <Link
                  to="/careers"
                  className="hover:text-[#fb6962] transition-colors"
                >
                  Products
                </Link>
              </li>
            </ul>
          </div>

          {/* Contact Us */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-center sm:text-left">
              Contact Us
            </h3>
            <ul className="space-y-2 text-sm lg:text-base text-center sm:text-left">
              <li className="flex items-center sm:justify-start">
                <Mail className="mr-2 h-5 w-5 text-[#fb6962]" />
                <Link
                  to="mailto:info@nebulatech.com"
                  className="hover:text-[#fb6962] transition-colors"
                >
                  hr@nebula-technology.com
                </Link>
              </li>
              <li className="flex items-center sm:justify-start">
                <Phone className="mr-2 h-5 w-5 text-[#fb6962]" />
                <Link
                  to="tel:+918668768612"
                  className="hover:text-[#fb6962] transition-colors"
                >
                  +91 8668768612 / 7507316272
                </Link>
              </li>
              <li className="flex items-start justify-center sm:justify-start">
                <MapPin className="mr-2 h-5 w-5 text-[#fb6962] mt-1 flex-shrink-0" />
                <div className="leading-snug text-sm text-left">
                  CTS No 349, Flat No. T-4, 3rd floor, Above Anand Asha
                  Restaurant Bar, Opposite Durga Mata Mandir, Near Ideal Metro
                  Station, Paud Road, Kothrud, Pune-411038
                </div>
              </li>
            </ul>
          </div>

          {/* Newsletter */}
          <div>
            <h3 className="text-lg font-semibold mb-4 text-center sm:text-left">
              Newsletter
            </h3>
            <p className="text-sm lg:text-base mb-4 text-center sm:text-left">
              Join us to stay updated with our latest innovations
            </p>
            <form onSubmit={handleSignUp} className="flex flex-col space-y-2">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-gray-800 border-gray-700 text-white p-2 rounded text-sm"
                required
              />
              <button
                type="submit"
                className="bg-[#fb6962] hover:bg-[#d85850] text-white p-2 rounded text-sm"
              >
                Subscribe
              </button>
            </form>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="bg-white py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center text-xs sm:text-sm text-black">
          © 2024 Nebula Technology. All rights reserved.
        </div>
      </div>
    </footer>
  );
}
