import React, { useState } from 'react';
import { User, Mail, Lock, Eye, EyeOff } from 'lucide-react';
import { Link } from 'react-router-dom';
import videobg from '../assets/videobg.mp4';
import icon from '../assets/lngico/icon.png';

const Signup = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match!");
      return;
    }

    console.log('Sign Up Attempted', { fullName, email, password });
  };

  return (
    <div className="relative w-full h-screen overflow-hidden">
      <video
        className="absolute top-0 left-0 w-full h-full object-cover"
        autoPlay
        muted
        loop
        playsInline
      >
        <source src={videobg} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="absolute inset-0  flex items-center justify-center p-4 mt-5">
        <div className="w-full max-w-md bg-white rounded-2xl shadow-2xl border border-gray-200 overflow-hidden">
          <div className="p-4 space-y-1">
            {/* <div className="flex justify-center">
              <Link to="/" className="block">
                <div className="flex flex-shrink-0 items-center">
                  <div className="flex flex-col">
                    <h1 className="flex items-center text-xl font-bold mt-3 mx-3">
                      <span style={{ color: '#b71c1c', fontSize: 46 }}>n</span>{" "}
                      <img
                        alt="Nebula logo"
                        src={icon}
                        className="w-5 h-5 logo mt-2"
                      />{" "}
                      <span style={{ color: '#b71c1c', fontSize: 46 }}>bula</span>
                    </h1>
                    <div className="flex flex-col items-start mt-1">
                      <span className="custom-background text-md font-bold">
                        TECHNOLOGY
                      </span>
                      <span className="text-md italic text-blue-600 text-center font-cursive mx-3">
                        where creation happens...
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
            <p className="text-center text-black-600 text-xl font-bold mt-2 mb-4">
              Join our community
            </p>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <User className="text-gray-500" size={20} />
                </div>
                <input
                  type="text"
                  placeholder="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="w-full pl-10 pr-4 py-3 bg-gray-100 rounded-xl 
                    text-gray-800 placeholder-gray-500 
                    focus:outline-none focus:ring-2 focus:ring-blue-300 
                    border border-gray-300"
                  required
                />
              </div>

              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="text-gray-500" size={20} />
                </div>
                <input
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full pl-10 pr-4 py-3 bg-gray-100 rounded-xl 
                    text-gray-800 placeholder-gray-500 
                    focus:outline-none focus:ring-2 focus:ring-blue-300 
                    border border-gray-300"
                  required
                />
              </div>

              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="text-gray-500" size={20} />
                </div>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full pl-10 pr-10 py-3 bg-gray-100 rounded-xl 
                    text-gray-800 placeholder-gray-500 
                    focus:outline-none focus:ring-2 focus:ring-blue-300 
                    border border-gray-300"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>

              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="text-gray-500" size={20} />
                </div>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full pl-10 pr-10 py-3 bg-gray-100 rounded-xl 
                    text-gray-800 placeholder-gray-500 
                    focus:outline-none focus:ring-2 focus:ring-blue-300 
                    border border-gray-300"
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"
                >
                  {showConfirmPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>

              <button
                type="submit"
                className="w-full py-3 bg-blue-500 text-white 
                  rounded-xl hover:bg-blue-600 
                  transition duration-300 
                  border border-blue-500 
                  font-semibold uppercase tracking-wide"
              >
                Sign Up
              </button>
            </form>

            <div className="text-center mt-4">
              <p className="text-sm text-gray-600">
                Already have an account? {' '}
                <Link
                  to="/signin"
                  className="text-blue-600 hover:underline font-semibold"
                >
                  Sign In
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;