import React from 'react';
import { useNavigate } from 'react-router-dom';

const GetInTouch = () => {
  const navigate = useNavigate();
  return (
    <section className="counters relative bg-fixed bg-cover bg-center text-white py-5 sm:py-8 md:py-12">
      {/* Overlay */}
      <div className="absolute inset-0 bg-gray-800 bg-opacity-60"></div>

      {/* Content */}
      <div className="relative z-10 max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6 items-center px-4 sm:px-6 lg:px-16">
        {/* Column 1: Text */}
        <div>
          <h2 className="text-3xl sm:text-4xl font-bold mb-3 sm:mb-4 tracking-widest">
            Contact Us
          </h2>
          <p className="text-base sm:text-lg mb-4 sm:mb-6 leading-relaxed">
            Ready to transform your business with innovative IT solutions? Contact us today for a personalized consultation, and let’s discuss how our services and products can help you achieve your goals.
          </p>
        </div>

        {/* Column 2: Button */}
        <div className="flex justify-start md:justify-center mx-3 sm:mx-5">
          <button
            onClick={() => navigate('/contact')}
            className="bg-gray-900 text-white px-6 py-3 rounded hover:bg-[#fb6962] transition"
          >
            Get In Touch
          </button>
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
