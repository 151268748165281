import React from "react";
import { Link, useNavigate } from "react-router-dom";
import landingimg from "../assets/img/landingimg.jpg";
// import img1 from "../assets/img/contact1.jpg";
// import img2 from "../assets/img/landingimg.jpg";
import { Calendar, Laptop, Code, Users } from "lucide-react";
import rightArrow from "../assets/icons/right-arrow.png";
import { motion } from "framer-motion";
import GetInTouch from "./GetInTouch";
// import Clients from './Clients';
// import  Products, { HomeProduct } from './Products';
import OurTeam from "./OurTeam";

const About = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="relative w-full h-[600px] overflow-hidden">
        <img
          src={landingimg}
          alt="Contact page"
          className="absolute w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center">
          <h1 className="text-3xl font-semibold text-white mb-4">About Us</h1>
          <div className="flex items-center gap-2 text-white">
            <Link to="/" className="hover:text-gray-200">
              Home
            </Link>
            <span className="text-lg">›</span>
            <span>About Us</span>
          </div>
        </div>
      </div>
      <div className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 relative">
            {/* Left Section */}
            <div>
              <h3 className="text-3xl mb-3 text-[#fb6962] relative inline-block after:content-[''] after:block after:w-full after:h-1 after:bg-[#fb6962] after:mt-1 font-['Raleway']">
                We are
              </h3>

              <h1 className="mt-8 text-4xl md:text-5xl font-bold  text-gray-900  bg-clip-text mb-3">
                Leaders in Software Development
              </h1>
              {/* <h2 className="text-4xl font-semibold text-center mt-4">Our Growth Journey</h2> */}
              {/* <h2 className="font-heading">
                Leaders in Software Development
              </h2> */}

              <p className="text-gray-700 mb-8 leading-relaxed">
                Welcome to Nebula technology, your number one source for all
                things in Software Solutions. We're dedicated to giving you the
                very best of Software Solutions, with a focus on Java, Android,
                Spring Hibernate, IOS, PHP, Digital Marketing etc. We have
                expertise in different domains and we are passionate about
                upgrading ourselves in new technologies as well. Our long-term
                goal is to provide end-to-end service to our clients and make
                them satisfied with our service. We are working with different
                technologies to implement clients' innovative ideas in real-time
                environments. We hope you enjoy our services and products as
                much as we enjoy offering them to you. If you have any questions
                or comments, please don't hesitate to contact us.
              </p>
              {/* <p className="text-gray-700 mb-8 leading-relaxed">
                Our team of experienced developers, designers, and product managers work
                closely with our clients to understand their needs. We have
                developed various products in AI, SaaS, Fintech, Insurance, and
                Healthcare domains.
              </p> */}
              <button
                onClick={() => navigate("/contact")}
                className="bg-gray-800 text-white px-6 py-3 rounded hover:bg-gray-700 transition flex items-center gap-2"
              >
                Contact Us
                <img
                  src={rightArrow}
                  alt="Right Arrow"
                  className="text-lg w-5 h-5"
                />
              </button>
            </div>

            {/* Right Section - New Image Layout */}
            <div className="relative h-full w-full">
              <div className="top-0 left-0 w-full h-full flex items-center justify-center">
                <div className="relative w-full lg:w-[600px] max-w-4xl">
                  <img
                    src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
                    alt="Presentation"
                    className="rounded-lg shadow-lg w-full h-auto"
                  />
                  <div
                    className="absolute bg-gray-900 text-white p-6 rounded-lg shadow-md"
                    style={{
                      top: "80%",
                      left: "80%",
                      transform: "translate(-50%, -50%) translateY(50%)", // Adjust placement
                      zIndex: 10,
                    }}
                  >
                    <p className="text-2xl font-bold"> Innovation, </p>
                    <p className="text-sm"> Integrity, Client Satisfaction. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <OurTeam />
      <div className="bg-[#eff6ff] py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-3xl font-bold text-[#fb6962] text-center">
            Thriving
          </h1>
          <h2 className="text-4xl font-semibold text-center mt-4">
            Our Growth Journey
          </h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8 mt-20">
            {[
              {
                icon: <Calendar className="h-5 w-5 text-white" />,
                value: 100,
                label: "Trusted Expertise",
                description: "A history of success with exceptional products.",
              },
              {
                icon: <Laptop className="h-5 w-5 text-white" />,
                value: 123,
                label: "Projects",
                description: "Web and mobile apps solutions",
              },
              {
                icon: <Code className="h-5 w-5 text-white" />,
                value: 15,
                label: "Tech Stack",
                description: "Various tech stack and different projects",
              },
              {
                icon: <Users className="h-5 w-5 text-white" />,
                value: 50,
                label: "Above 50 Happy Clients",
                description: "Carefully chosen, Expertly trained team",
              },
            ].map((item, index) => (
              <motion.div
                key={index}
                className="bg-white shadow-md rounded-lg p-6"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="flex items-center">
                  <div className="h-8 w-8 bg-[#fb6962] rounded-full flex items-center justify-center">
                    {item.icon}
                  </div>
                  <span className="ml-3 text-2xl font-bold text-gray-900">
                    {item.value}
                  </span>
                </div>
                <p className="mt-4 text-gray-600 font-bold">{item.label}</p>
                <p className="mt-2 text-gray-600">{item.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      <div className="py-2 mb-2">
        <GetInTouch />
      </div>
    </>
  );
};

export default About;
