import Products from "./Products";
import landingimg from "../assets/img/product1.jpg";
import { Link } from "react-router-dom";

const OurProduct = () => {
  return (
    <>
      <div className="relative w-full h-[600px] overflow-hidden">
        <img
          src={landingimg}
          alt="Contact page"
          className="absolute w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center">
          <h1 className="text-3xl font-semibold text-white mb-4">
            Our Products
          </h1>
          <div className="flex items-center gap-2 text-white">
            <Link to="/" className="hover:text-gray-200">
              Home
            </Link>
            <span className="text-lg">›</span>
            <span>Our Products</span>
          </div>
        </div>
      </div>
      <Products />
    </>
  );
};

export default OurProduct;
