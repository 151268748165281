import React from "react";
import { Link, useParams } from "react-router-dom";
import clientBased from "../assets/clientBased.mp4";
import erp_service from "../assets/erp_service.mp4";
import hr_solution from "../assets/hr_solution.mp4";
import product_development from "../assets/productDevelopment.mp4";
import digital_marketing from "../assets/digitalMarketing.mp4";
import erp_img from "../assets/img/services.jpg";
import client_img from "../assets/img/clientservice.jpg";
import product_img from "../assets/img/productdevelopment.jpg";
import hr_img from "../assets/img/hrsolutions1.jpg";
import digital_img from "../assets/img/digitalMarketing.jpg";
import Technologies from "./Technologies";

import {
  Server,
  Activity,
  Shield,
  Layers,
  BarChart2,
  Smartphone,
} from "lucide-react";




// Service data configuration
const servicesData = {
  "erp-solutions": {
    title: "ERP Solutions",
    subtitle: "Enterprise Resource Planning Systems",
    description: (
      <>
        <p>
          Our ERP solutions streamline and integrate core business processes
          like finance, inventory, HR, and sales into a unified platform,
          enabling real-time analytics, enhanced productivity, and better
          decision-making.
        </p>
        <p className="indent-10">
          By automating repetitive tasks, reducing errors, and leveraging
          advanced technologies like AI and cloud computing, our ERP systems
          optimize resources, reduce costs, and improve customer satisfaction,
          driving operational excellence and sustainable growth for our clients.
        </p>
      </>
    ),
    video: erp_service,
    image: erp_img, // Add your image path here
  },
  "client-based-applications": {
    title: "Client-Based Applications",
    subtitle: "Custom Software Development",
    description: (
      <>
        <p>
          Bring your business ideas to life with our customized software
          solutions. Leveraging our expertise in cutting-edge technologies, we
          deliver robust, scalable applications designed to address your unique
          challenges and enhance your competitive edge. Whether you need
          enterprise-level software, mobile apps, or web-based platforms, our
          solutions are tailored to meet your specific business goals.{" "}
        </p>

        <p className="indent-10">
          Our team ensures seamless integration, intuitive user experience, and
          high performance, enabling your business to adapt and thrive in a
          dynamic market. From consultation and development to deployment and
          support, we guide you through every step of the software journey to
          ensure success.
        </p>
      </>
    ),
    video: clientBased,
    image: client_img, // Add your image path here
  },
"product-development": {
  title: "Product Development Services",
  subtitle: "Innovative Product Development",
  description:
    (
      <>
      <p>
      Drive your business forward with our comprehensive product development services. We specialize in turning your ideas into market-ready solutions through a structured and innovative approach. Whether you're developing software, hardware, or digital platforms, our team works closely with you from concept to completion, ensuring your product meets the highest quality standards.</p>
      <p className="indent-10">
       With a focus on scalability, efficiency, and user experience, we craft solutions that align with your business goals. From prototyping and testing to deployment and continuous improvement, we provide the expertise and tools needed to bring your vision to life and succeed in a competitive marketplace.</p>
      </>
    ),
  video: product_development,
  image: product_img, // Add your image path here
},

  "hr-solutions": {
  title: "HR Solutions",
  subtitle: "Comprehensive Human Resource Management",
  description:
  (
    <>
    <p>    
      Empower your HR operations with our advanced and fully customizable solutions. From recruitment and onboarding to performance tracking, payroll management, and employee development, our HR systems are designed to simplify and automate complex processes. By integrating cutting-edge technology and intuitive interfaces, we help you save time, reduce administrative burden, and enhance employee engagement. </p>

    <p className="indent-10">
    Our solutions are tailored to meet your unique organizational needs, ensuring seamless workflows and compliance with ever-evolving regulations. Whether you're managing a small team or a global workforce, our scalable HR tools provide actionable insights, foster collaboration, and support strategic decision-making to help you build and maintain a thriving workplace.</p>
    </>
    ),
  video: hr_solution,
  image: hr_img, // Add your image path here
},

  "digital-marketing": {
  title: "Digital Marketing",
  subtitle: "Strategic Online Growth Solutions",
  description:
  (
    <>
    <p>
    Amplify your digital presence and achieve measurable success with our comprehensive digital marketing services. In partnership with WebCraft Creative Studio, we deliver cutting-edge solutions tailored to align with your business goals.  </p>
    <p className="indent-10">
    From SEO and PPC campaigns to social media strategies, email marketing, and content creation, we combine creativity and data-driven insights to ensure your brand reaches the right audience at the right time. Together, we focus on boosting engagement, driving traffic, and converting leads into loyal customers. Whether you're building brand awareness, launching a new product, or expanding into new markets, our collaboration ensures maximum impact and sustainable growth.</p>
    </> 
    ),
  video: digital_marketing,
  image: digital_img, // Add your image path here
},

};

const ServiceIcons = {
  "erp-solutions": Server,
  "client-based-applications": Smartphone,
  "product-development": Shield,
  "hr-solutions": BarChart2,
  "digital-marketing": Activity,
};

const Services = () => {
  const { serviceType } = useParams();
  const currentService = serviceType ? servicesData[serviceType] : null;

  const getBreadcrumbItems = () => {
    const items = [
      { name: "Home", path: "/" },
      { name: "Services", path: "/services" },
    ];
    if (currentService) {
      items.push({
        name: currentService.title,
        path: `/services/${serviceType}`,
      });
    }
    return items;
  };

  const ServiceDetails = ({ service }) => {
    const ServiceIcon = ServiceIcons[serviceType] || Layers;

    return (
      <div className="space-y-16">
        {/* Overview Section */}
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <div className="space-y-6">
            <div className="flex items-center space-x-4">
              <ServiceIcon className="w-12 h-12 text-blue-600" />
              <h2 className="text-3xl font-bold text-gray-900">
                {service.title}
              </h2>
            </div>
            <p className="text-lg text-gray-600 leading-relaxed">
              {service.description}
            </p>
          </div>
          <div className="relative">
            <video
              src={service.video}
              alt={service.title}
              className="rounded-lg shadow-2xl transform hover:scale-105 transition-transform"
              autoPlay
              loop
              muted
            />
          </div>
        </div>

   
        <Technologies/>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <div
        className="relative h-[600px]"
        style={{
          backgroundImage: `url(${
            currentService?.image || "/default-hero.jpg"
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0 bg-black/40">
          <div className="container mx-auto px-4 h-full flex flex-col items-center justify-center text-center">
            <h1 className="text-5xl font-bold text-white mb-6">
              {currentService ? currentService.title : "Our Services"}
            </h1>
            {currentService && (
              <p className="text-xl text-gray-200 max-w-2xl">
                {currentService.subtitle}
              </p>
            )}
            <nav className="mt-8 flex items-center space-x-2 text-gray-300">
              {getBreadcrumbItems().map((item, index, array) => (
                <React.Fragment key={item.path}>
                  <Link
                    to={item.path}
                    className={`hover:text-white transition-colors duration-200 ${
                      index === array.length - 1 ? "text-blue-300" : ""
                    }`}
                  >
                    {item.name}
                  </Link>
                  {index < array.length - 1 && (
                    <span className="text-gray-500">›</span>
                  )}
                </React.Fragment>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {/* Content Section */}
      <div className="container mx-auto px-4 py-16">
        {currentService ? (
          <ServiceDetails service={currentService} />
        ) : (
          // Services Overview Grid
          <div className="grid md:grid-cols-2 gap-8">
            {Object.entries(servicesData).map(([key, service]) => (
              <Link
                key={key}
                to={`/services/${key}`}
                className="group relative overflow-hidden rounded-2xl shadow-lg bg-white hover:shadow-2xl transition-all duration-300"
              >
                <div className="absolute inset-0">
                  <video
                    src={service.video}
                    alt={service.title}
                    className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-300"
                    autoPlay
                    loop
                    muted
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent" />
                </div>
                <div className="relative p-8 min-h-[300px] flex flex-col justify-between items-start">
                  <h3 className="text-2xl font-bold text-white mb-4">
                    {service.title}
                  </h3>
                  <p className="text-sm text-gray-200">{service.subtitle}</p>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Services;
