import React from "react";

// Import local images
import wheel from "../assets/clients/wheel.png";
import mahadev_clg from "../assets/clients/mahadev_clg.jpg";
import milk_bank from "../assets/clients/milk_bank.jpg";
import showroom from "../assets/clients/showroom.jpeg";
import garage from "../assets/clients/garage.webp";
import society from "../assets/clients/society.png";

const solutions = [
  {
    name: "College ERP System",
    image: wheel,
    description: "Comprehensive educational resource planning solution",
    moreInfo: "Detailed features of the ERP system including modules and integrations."
  },
  {
    name: "Construction ERP App",
    image: mahadev_clg,
    description: "Integrated construction management platform",
    moreInfo: "Project management, team coordination, and resource management."
  },
  {
    name: "Milk Bank System App",
    image: milk_bank,
    description: "Advanced milk bank management solution",
    moreInfo: "Track and manage milk donations, inventory, and usage."
  },
  {
    name: "Showroom ERP Application",
    image: showroom,
    description: "Complete showroom management system",
    moreInfo: "Manage stock, sales, and customer information in one platform."
  },
  {
    name: "Society ERP System",
    image: society,
    description: "Smart society management platform",
    moreInfo: "Manage society members, maintenance requests, and communications."
  },
  {
    name: "Garage On Wheel System",
    image: garage,
    description: "Mobile garage management solution",
    moreInfo: "On-site service management for garages and mobile vehicles."
  },
];

const Products = () => {
  return (
    <div className="min-h-screen bg-gradient-to-r bg-white my-4 mx-2">
      <div className="container mx-auto px-4 py-16">
        <h1 className="text-center text-5xl font-bold mb-16 text-black">
          Explore Our Work
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {solutions.map((solution) => (
            <div
              key={solution.name}
              className="relative group w-full h-96  overflow-hidden"
            >
              {/* Card container with border-radius on all corners */}
              <div className="relative w-full h-full overflow-hidden rounded-xl bg-transparent p-4">
                {/* Front side of the card with gradient background */}
                <div className="absolute w-full h-full backface-hidden bg-gradient-to-r from-black via-gray-800 to-gray-500 p-4 rounded-xl flex flex-col items-center justify-center transition-all duration-700 transform">
                  <div className="mb-4">
                    <img
                      src={solution.image}
                      alt={solution.name}
                      className="w-full h-40 object-contain rounded-lg"
                    />
                  </div>
                  <h2 className="mb-2 text-center text-xl font-semibold text-white">
                    {solution.name}
                  </h2>
                  <p className="text-center text-sm text-gray-300 px-1 ">
                    {solution.description}
                  </p>
                </div>

                {/* Back side of the card (vertical swipe animation) */}
                <div className="absolute w-full h-full backface-hidden bg-[rgba(200,93,93,0.8)] p-4 rounded-xl flex items-center justify-center transform translate-y-full opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-700 ease-in-out">
                  <div className="text-center text-xl font-bold text-white rounded-xl ">
                    <p>{solution.moreInfo}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Products;
