
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import lauro from '../assets/clients/lauro.jpg';
 import mahadev_clg from '../assets/clients/mahadev_clg.jpg';
import tarasa from '../assets/clients/tarasa.png';
import tech_mahindra from '../assets/clients/tech_mahindra.png';
import shreeKrishna from '../assets/clients/shreeKrishna.jpg';
import showroom from '../assets/clients/showroom.jpeg';
// import adidasLogo from '../assets/clients/milk-cup.jpg';
import webcraft from '../assets/clients/WCLogo.png'

const Clients = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const logos = [
    { src: lauro, alt: 'Lauro' },
   { src: mahadev_clg, alt: 'Mahadev College' },
    { src: tarasa, alt: 'Tarasa' },
    { src: tech_mahindra, alt: 'Tech Mahindra' },
    { src: shreeKrishna, alt: 'ShreeKrishna' },
    { src: showroom, alt: 'Showroom' },
    { src: webcraft, alt: 'Webcraft' },

    // { src: adidasLogo, alt: 'Adidas' },
  ];

  return (
    <div className="container mx-auto px-4 py-8 mb-2">
      <h2 className="text-center font-bold text-2xl mb-10">Our Clients</h2>
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} className="slide">
            <img src={logo.src} alt={logo.alt} className="max-h-20 mt-2 object-contain hover:scale-110 transition-transform duration-300" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Clients;
