import React from 'react';
import { Home, Phone, Mail } from 'lucide-react';
import landingimg from '../assets/img/landingimg.jpg';
import {Link} from 'react-router-dom';
const ContactUs = () => {
  return (
    <div>
      {/* Hero Section */}
      <div className="relative h-[600px] overflow-hidden">
        <img
          src={landingimg}
          alt="Contact page"
          className="absolute w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black/50 flex flex-col items-center justify-center text-white">
          <h1 className="text-3xl font-semibold mb-4">Contact Us</h1>
          <div className="flex items-center gap-2">
            <Link to="/" className="hover:text-gray-200">Home</Link>
            <span>›</span>
            <span>Contact Us</span>
          </div>
        </div>
      </div>

      {/* Contact Section */}
      <div className=" py-16 px-4">
        <div className="max-w-5xl mx-auto">
          {/* Header */}
          <div className="text-center mb-12">
            <h2 className="text-4xl font-semibold mb-4">Contact Us</h2>
            <p className="text-lg text-gray-600">
              We'd love to hear from you! Whether you have questions, need support, or want to learn more about our services, our team is here to help.
            </p>
          </div>

          {/* Contact Content */}
          <div className="flex flex-col lg:flex-row gap-12">
            {/* Contact Info */}
            <div className="lg:w-1/2 space-y-8">
              <ContactInfo
                Icon={Home}
                title="Address"
                description={`CTS No 349, Flat No. T-4, 3rd floor, Above Anand Asha Restaurant Bar, Opposite Durga Mata Mandir, Near Ideal Metro Station, Paud Road, Kothrud, Pune-411038.`}
              />
              <ContactInfo
                Icon={Phone}
                title="Phone"
                description="+91 8668768612 / 7507316272"
              />
              <ContactInfo
                Icon={Mail}
                title="Email"
                description="hr@nebula-technology.com"
              />
            </div>

            {/* Contact Form */}
            <div className="lg:w-1/2">
              <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
                <h2 className="text-xl font-semibold mb-4">Send Message</h2>
                <form className="space-y-4">
                  <InputField type="text" placeholder="Full Name" />
                  <InputField type="email" placeholder="Email" />
                  <textarea
                    required
                    rows={3}
                    className="w-full border-b-2 border-gray-300 py-1.5 text-sm focus:border-cyan-500 focus:outline-none resize-none"
                    placeholder="Type your Message..."
                  ></textarea>
                  <button
                    type="submit"
                    className="w-full bg-cyan-500 text-white py-2 rounded-md hover:bg-cyan-600 transition-colors duration-300"
                  >
                    Send
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Map Section */}
      <div className="w-full px-2">
        <iframe
          title="nebula_technology"
          className="rounded-md w-full"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15133.991230574691!2d73.8231593!3d18.5063932!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x12d81c9f02199c77!2sNebula%20Technology!5e0!3m2!1sen!2sin!4v1646203735297!5m2!1sen!2sin"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

const ContactInfo = ({ Icon, title, description }) => (
  <div className="flex items-start gap-4">
    {/* Icon Container */}
    <div className="flex-shrink-0 w-14 h-14 bg-cyan-500 rounded-full flex items-center justify-center">
      <Icon className="text-white w-6 h-6" />
    </div>

    {/* Text Content */}
    <div>
      <h4 className="text-lg font-semibold text-cyan-600 mb-1">{title}</h4>
      <p className="text-gray-600 text-sm whitespace-pre-wrap">{description}</p>
    </div>
  </div>
);


const InputField = ({ type, placeholder }) => (
  <input
    type={type}
    required
    className="w-full border-b-2 border-gray-300 py-1.5 text-sm focus:border-cyan-500 focus:outline-none transition-colors"
    placeholder={placeholder}
  />
);

export default ContactUs;
