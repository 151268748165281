import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import videobg from '../assets/videobg.mp4';
import icon from '../assets/lngico/icon.png';
import '../components/Style.css';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Sign In Attempted', { email, password });
  };

  return (
    <div className="relative w-full h-screen overflow-hidden flex items-center justify-center">
      {/* Video Background */}
      <video
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
        autoPlay
        muted
        loop
        playsInline
      >
        <source src={videobg} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Overlay */}
      <div className="absolute inset-0 bg-black/40 z-1" />

      {/* Form Section */}
      <div className="relative z-10 w-full max-w-full px-4 sm:px-6 lg:px-8 mt-5">
        <div className="w-full max-w-md mx-auto bg-white/90 rounded-xl shadow-lg p-4 sm:p-6 md:p-8 lg:p-10 xl:p-12 space-y-4 md:space-y-6">

          {/* Logo Section */}
          <div className="flex justify-center">
            <Link to="/" className="block">
              <div className="flex flex-shrink-0 items-center">
                <div className="flex flex-col items-center">
                  <h1 className="flex items-center text-xl font-bold mt-2 mx-2">
                    <span style={{ color: '#b71c1c', fontSize: '2rem' }}>n</span>{" "}
                    <img
                      alt="Nebula logo"
                      src={icon}
                      className="w-6 sm:w-8 logo mx-1"
                    />{" "}
                    <span style={{ color: '#b71c1c', fontSize: '2rem' }}>bula</span>
                  </h1>
                  <div className="flex flex-col items-center mt-1">
                    <span className="custom-background text-xs sm:text-sm font-bold">
                      TECHNOLOGY
                    </span>
                    <span className="text-xs sm:text-sm italic text-blue-600 text-center font-cursive">
                      where creation happens...
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          {/* Title */}
          <h2 className="text-center text-lg sm:text-xl md:text-2xl font-bold text-gray-800">
            Sign in to your account
          </h2>

          {/* Form */}
          <form onSubmit={handleSubmit} className="space-y-3 sm:space-y-4 md:space-y-5 lg:space-y-6">
            {/* Email Input */}
            <div>
              <label htmlFor="email" className="block text-xs sm:text-sm md:text-base font-medium text-gray-800">
                Email Address
              </label>
              <div className="relative mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  autoComplete="email"
                  className="w-full px-2 py-1 sm:px-3 sm:py-2 md:px-4 md:py-3 text-xs sm:text-sm md:text-base rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
            </div>

            {/* Password Input */}
            <div>
              <label htmlFor="password" className="block text-xs sm:text-sm md:text-base font-medium text-gray-800">
                Password
              </label>
              <div className="relative mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  autoComplete="current-password"
                  className="w-full px-2 py-1 sm:px-3 sm:py-2 md:px-4 md:py-3 text-xs sm:text-sm md:text-base rounded-md border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
            </div>

            {/* Forgot Password */}
            <div className="flex justify-end">
              <Link to="#" className="text-xs sm:text-sm md:text-base text-blue-500 hover:underline">
                Forgot Password?
              </Link>
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white py-1.5 sm:py-2 md:py-3 rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 focus:outline-none text-xs sm:text-sm md:text-base"
              >
                Sign In
              </button>
            </div>
          </form>

          {/* Footer */}
          <p className="text-center text-xs sm:text-sm md:text-base text-gray-600">
            Don't have an account?{' '}
            <Link to="/signup" className="text-blue-500 hover:underline font-semibold">
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Signin;