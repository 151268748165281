import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { Server, Code, Activity, Users, TvMinimalPlay } from "lucide-react";
import erpsolution from "../assets/img/erpsolution.jpg";

const ServicesHome = () => {
  const navigate = useNavigate(); // Initialize the navigate function

  const services = [
    {
      icon: <Server className="w-8 h-8" />,
      title: "ERP Services",
      description:
        "Comprehensive enterprise resource planning solutions to streamline your business operations and boost efficiency.",
      gradient: "from-blue-500 to-cyan-400",
      shadowColor: "shadow-blue-500/30",
      path: "/services/erp-solutions", // Path for this service
    },
    {
      icon: <Users className="w-8 h-8" />,
      title: "Product Development",
      description:
        "Transform your ideas into reality with our end-to-end product development services, from concept design to market-ready solutions tailored to your needs.",
      gradient: "from-red-500 to-yellow-400",
      shadowColor: "shadow-green-500/30",
      path: "/services/product-development", // Path for this service
    },
    {
      icon: <Code className="w-8 h-8" />,
      title: "Client-Based Applications",
      description:
        "Delivering custom client-based solutions with seamless web and mobile applications designed to enhance user experience and drive business success.",
      gradient: "from-purple-500 to-pink-500",
      shadowColor: "shadow-purple-500/30",
      path: "/services/client-based-applications",
      backgroundimg: { erpsolution },
    },
    {
      icon: <TvMinimalPlay className="w-8 h-8" />,
      title: "Digital Marketing",
      description:
        "Boost your online presence with our expert digital marketing services, including SEO, social media management, and targeted ad campaigns to drive growth and engagement.",
      gradient: "from-orange-300 to-red-600",
      shadowColor: "shadow-orange-500/30",
      path: "/services/digital-marketing", // Path for this service
    },
    {
      icon: <Activity className="w-8 h-8" />,
      title: "HR Solutions",
      description:
        "Comprehensive HR solutions tailored to streamline recruitment, employee management, and organizational development, fostering a productive and engaged workforce.",
      gradient: "from-green-500 to-emerald-400",
      shadowColor: "shadow-orange-500/30",
      path: "/services/hr-solutions", // Path for this service
    },
  ];

  return (
    <div className="py-16 px-4 sm:px-6 lg:px-8 bg-gray-50">
      <div className="max-w-7xl mx-auto">
        {/* Header Section */}
        <div className="text-center mb-16 relative">
          <div className="absolute inset-0 -z-10">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-100/50 to-blue-100/50 blur-3xl" />
          </div>

          <span className="inline-flex items-center px-6 py-2 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white text-sm font-medium">
            What We Offer
          </span>

          <h2 className="mt-8 text-3xl sm:text-4xl lg:text-5xl font-bold text-gray-900 bg-clip-text">
            Our Featured Services
          </h2>

          <p className="mt-4 sm:mt-6 text-base sm:text-lg lg:text-xl text-gray-600 max-w-2xl mx-auto">
            Empowering businesses with cutting-edge technology solutions
            tailored to modern needs
          </p>
        </div>

        {/* Services Section */}
        <div
          className={`flex justify-center ${
            services.length < 3 ? "flex-wrap" : ""
          }`}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 justify-items-center">
            {services.map((service, index) => (
              <div
                key={index}
                className={`group relative bg-white rounded-2xl p-6 sm:p-8 transition-all duration-300 hover:-translate-y-2 h-full`}
              >
                <div
                  className={`absolute inset-0 bg-gradient-to-br ${service.gradient} opacity-0 group-hover:opacity-10 rounded-2xl transition-opacity duration-300`}
                />
                <div className="relative flex flex-col items-center text-center h-full">
                  {/* Icon */}
                  <div
                    className={`mb-4 sm:mb-6 p-4 rounded-xl bg-gradient-to-br ${service.gradient} ${service.shadowColor} shadow-lg group-hover:shadow-2xl transition-all duration-300`}
                  >
                    <div className="text-white group-hover:animate-pulse">
                      {service.icon}
                    </div>
                  </div>

                  {/* Title */}
                  <h3 className="text-lg sm:text-xl lg:text-2xl font-bold text-gray-900 mb-2 sm:mb-4">
                    {service.title}
                  </h3>

                  {/* Description */}
                  <p className="text-sm sm:text-base lg:text-lg text-gray-600 mb-4 sm:mb-6 leading-relaxed">
                    {service.description}
                  </p>

                  {/* Button */}
                  <button
                    onClick={() => navigate(service.path)} // Navigate to the service path
                    className="inline-flex items-center px-5 py-2 sm:px-6 sm:py-3 rounded-full bg-gray-100 text-gray-900 font-medium text-sm sm:text-base transition-all duration-300 hover:bg-gradient-to-r hover:from-gray-900 hover:to-gray-700 hover:text-white group"
                  >
                    Learn More
                    <svg
                      className="w-4 h-4 sm:w-5 sm:h-5 ml-2 transform transition-transform duration-300 group-hover:translate-x-1"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M13 7l5 5m0 0l-5 5m5-5H6"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesHome;
