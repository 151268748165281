import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import {
  FaReact,
  FaJava,
  FaTools,
  FaChartBar,
  FaProjectDiagram,
} from "react-icons/fa";

const Technologies = () => {
  // Initialize AOS with easing
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Animation will run only once
      easing: "ease-in-out", // Apply easing to the animations
    });
  }, []);

  const CARDS = [
    {
      title: "Frontend",
      icon: <FaReact className="text-indigo-900 text-7xl mb-2" />,
      technologies: ["HTML", "CSS", "JavaScript", "React" ,"Angular"],
    },
    {
      title: "Backend",
      icon: <FaJava className="text-red-600 text-7xl mb-2" />,
      technologies: ["Java", "Spring Boot", "MongoDB", "MySQL", "Node.js"],
    },
    {
      title: "Testing",
      icon: <FaTools className="text-violet-800 text-6xl mb-4" />,
      technologies: ["Manual Testing", "Jest", "Cucumber", "Selenium"],
    },
    {
      title: "Data Analyst",
      icon: <FaChartBar className="text-pink-500 text-6xl mb-4" />,
      technologies: ["Tableau", "PowerBI"],
    },
    {
      title: "Management",
      icon: <FaProjectDiagram className="text-orange-500 text-6xl mb-4" />,
      technologies: ["Git", "Agile", "JIRA", "Scrum"],
    },
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      <h2
        className="text-center font-bold text-3xl mb-10 text-gray-800"
        data-aos="fade-up"
      >
        Technologies We Employ
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 gap-6">
        {CARDS.map((card, index) => (
          <div
            key={index}
            className="relative bg-gradient-to-br from-slate-500 shadow-md rounded-lg p-6 overflow-visible group transition-transform duration-300 "
            data-aos="zoom-in" // AOS zoom-in effect
            data-aos-delay={index * 100} // Delay each card animation
            data-aos-duration="1200" // Duration for each card animation
            data-aos-easing="ease-in-out" // Apply easing to each card
          >
            {/* Gradient Effect on Hover */}
            <div className=" absolute inset-0 bg-gradient-to-br from-slate-600 opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-lg"></div>

            <div className="relative z-10 flex flex-col items-center group-hover:scale-110 transition-transform duration-500">
              {/* Card Icon */}
              {card.icon}
              {/* Card Title */}
              <h3 className="text-center text-xl font-bold text-gray-800 mb-4 group-hover:text-white transition-colors duration-300">
                {card.title}
              </h3>
            </div>

            {/* Technologies List */}
            <ul className="relative z-10 list-disc list-inside text-gray-700 text-sm font-extrabold">
              {card.technologies.map((tech, techIndex) => (
                <li
                  key={techIndex}
                  className="mb-2 group-hover:text-white transition-colors duration-300"
                >
                  {tech}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Technologies;
