import React from "react";
import { Link } from "react-router-dom";
import "../components/Style.css";
// import landingimg from '../assets/img/landingimg.jpg';
// import frontimg from '../assets/img/frontimg1.jpg';
import { TypeAnimation } from "react-type-animation";
import WhoWeAre from "./WhoWeAre";
import Counters from "./Counter";
import ServicesHome from "./ServicesHome";
import WhyUs from "./WhyUs";
import GetInTouch from "./GetInTouch";
import Clients from "./Clients";
// import home from '../assets/img/frontimg1.jpg'
import videobg from "../assets/videobg.mp4";

const Home = () => {
  return (
    <>
      <div className="hero-section">
        {/* <img src={home} alt="Background" className="hero-background" /> */}
        <video
          src={videobg}
          className="hero-background"
          autoPlay
          loop
          muted
          playsInline
        ></video>
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <div className="content-wrapper">
            <h1 className="hero-title">
              <TypeAnimation
                sequence={[
                  "We Offer Best Tech Web Solutions!",
                  3000,
                  "We Offer ERP Solutions!",
                  3000,
                  "We Offer Client-Based Applications!",
                  3000,
                  "We Offer Healthcare Solutions!",
                  3000,
                  "We Offer IT Support!",
                  3000,
                ]}
                wrapper="span"
                speed={50}
                style={{ display: "inline-block" }}
                repeat={Infinity}
              />
            </h1>

            <h2 className="hero-subtitle">
              Accelerate Your Business Growth by 10x with Us!
            </h2>
            <p className="hero-description">
              We are a professional IT company delivering expertise in software
              engineering solutions.
            </p>
            <Link to="/about" className="cta-button">
              View Our Work
            </Link>
          </div>
        </div>
      </div>

      <div className="mt-28">
        <WhoWeAre />
        <Counters />
        <ServicesHome />
        <WhyUs />
        <GetInTouch />
        <Clients />
      </div>
    </>
  );
};

export default Home;
