import './App.css';
import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Careers from './components/Careers';
// import Clients from './components/Clients';
import Footer from './components/Footer';
import Signin from './components/Signin';
import Signup from './components/Signup';
// import GetInTouch from './components/GetInTouch';
import ContactUs from './components/ContactUs';
import OurProduct from './components/OurProduct';
function App() {

  const ContactUsContent = () => (
    <div className="">
      <ContactUs />
    </div>
  );

  const location = useLocation();
  // const isAuthPage = location.pathname === "/signin" || location.pathname === "/signup";
  return (

    <div className="min-h-screen">
      {/* {!isAuthPage && <Navbar />} */}
      <Navbar/>
      <div className='mb-2'>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services/>} />
        <Route path="/services/:serviceType" element={<Services />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<ContactUsContent />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path='/ourproduct' element={<OurProduct/>}></Route>
      </Routes>
      </div>
      {/* {!isAuthPage && <Footer />} */}
      <Footer/>
    </div>

  );
}

export default App;


