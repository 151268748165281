import React, { useEffect, useState, useRef } from 'react';
import '../components/Style.css';

const Counter = ({ iconClass, target, label }) => {
    const [count, setCount] = useState(0);
    const counterRef = useRef();

    useEffect(() => {
        const increment = Math.ceil(target / 200);

        const startCounter = () => {
            setCount(0); // Reset count on each scroll into view
            const timer = setInterval(() => {
                setCount(prevCount => {
                    const nextCount = prevCount + increment;
                    if (nextCount >= target) {
                        clearInterval(timer);
                        return target;
                    }
                    return nextCount;
                });
            }, 20);
        };

        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        startCounter();
                    }
                });
            },
            { threshold: 0.5 }
        );

        if (counterRef.current) observer.observe(counterRef.current);

        return () => observer.disconnect();
    }, [target]);

    return (
        <div className="text-center" ref={counterRef}>
            <i className={`${iconClass} text-3xl sm:text-4xl mb-2 text-white`}></i>
            <div className="text-3xl sm:text-5xl font-bold text-white">+{count}</div>
            <h3 className="text-base sm:text-lg font-semibold text-white mt-2">{label}</h3>
        </div>
    );
};

const Counters = () => {
    const countersData = [
        { iconClass: 'fas fa-project-diagram', target: 123, label: 'Total Projects' },
        { iconClass: 'fas fa-gift', target: 80, label: 'Completed' },
        { iconClass: 'fas fa-users', target: 50, label: 'Happy Clients' },
        { iconClass: 'fas fa-star', target: 87, label: '5 Star Rating' },
    ];

    return (
        <section className="counters w-full relative bg-fixed bg-cover bg-center text-white py-10 mt-5">
            <div className="absolute inset-0 bg-black bg-opacity-40"></div>
            <div className="relative z-10 max-w-7xl mx-auto px-6 lg:px-16">
                {/* Flex container to wrap counters */}
                <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-10">
                    {countersData.map((data, index) => (
                        <div key={index} className="flex flex-col items-center text-white">
                            <Counter 
                                iconClass={data.iconClass} 
                                target={data.target} 
                                label={data.label} 
                            />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Counters;
