import React from "react";

const WhyUs = () => {
  return (
    <div className="bg-[#eff6ff] py-16">
      <div className="container mx-auto flex flex-col lg:flex-row items-center lg:items-start lg:space-x-8 px-4 md:px-8 lg:px-12">
        {/* Image Section */}
        <div className="relative w-full lg:w-1/2 max-w-4xl">
          <img
            src="https://images.unsplash.com/photo-1600880292203-757bb62b4baf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80"
            alt="Presentation"
            className="rounded-lg shadow-lg"
          />

         
          <div className="absolute bottom-4 right-4 bg-gray-900 text-white p-6 rounded-lg shadow-md z-10">
            <p className="text-2xl font-bold">100+</p>
            <p className="text-sm">Trusted, Happy & Satisfied Clients</p>
          </div>
        </div>

        {/* Content Section */}
        <div className="w-full lg:w-1/2 space-y-6">
          <h3 className="text-3xl text-[#fb6962] relative inline-block after:content-[''] after:block after:w-full after:h-1 after:bg-[#fb6962] after:mt-1 font-['Raleway']">
            Proven
          </h3>
          <h2 className="text-gray-800 font-bold text-3xl">Why Us?</h2>
          <p className="text-gray-600 leading-relaxed">
          At Nebula Technology, we go beyond providing IT solutions — we deliver value, innovation, and reliability that sets your business apart. Here’s why we are the trusted partner for companies across industries
          </p>

          {/* Bullet Points */}
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="flex-shrink-0 text-[#fb6962] mr-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </span>
              <p className="text-gray-600">
              We deliver cutting-edge IT services and innovative products tailored to meet your specific business needs.
              </p>
            </li>
            <li className="flex items-start">
              <span className="flex-shrink-0 text-[#fb6962] mr-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </span>
              <p className="text-gray-600">
              Our customer-focused approach ensures that your goals are at the center of every solution we create.
              </p>
            </li>
            <li className="flex items-start">
              <span className="flex-shrink-0 text-[#fb6962] mr-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </span>
              <p className="text-gray-600">
              We maintain the highest quality standards, delivering reliable and efficient solutions on time.
              </p>
            </li>
            <li className="flex items-start">
              <span className="flex-shrink-0 text-[#fb6962] mr-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </span>
              <p className="text-gray-600">
              Our scalable expertise supports businesses of all sizes, helping them grow and adapt to changing market demands.
              </p>
            </li>
          </ul>

          {/* Button */}
          {/* <div className="relative">
            <button className="bg-[#fb6962] text-white py-3 px-6 rounded-tl-[15px] rounded-br-[15px] text-xl font-semibold shadow-lg hover:bg-[#fa3930] transition-all">
              Contact Us
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default WhyUs;