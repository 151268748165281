import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import dipali from '../assets/img/Dipali.jpeg';
import ajinkya from '../assets/img/Ajinkya.jpeg'
import shital from '../assets/img/Shital.jpeg'
import prasad from '../assets/img/Prasad.jpeg'
import swapnil from '../assets/img/Swapnil.jpeg'
import kaustubh from '../assets/img/Kaustubh.jpeg'
import raghunath from '../assets/img/Raghu_sir.jpeg'
import priyanka from '../assets/img/Priyanka.jpeg'
import bharati from '../assets/img/Bharati.jpeg'
import vinay from '../assets/img/Vinay.png'
import onkar from '../assets/img/Onkar.jpeg'
import ashvini from "../assets/img/Ashvini_mam.jpeg"
import sonali from "../assets/img/Sonali.jpeg"


const teamMembers = [
  { name: "Raghunath Bade", position: "Founder , Managing Director", img: raghunath },
  { name: "Ashwini Jagdale", position: "HR", img: ashvini },
  { name: "Dipali Wadekar", position: "Technical Lead", img: dipali },
  { name: "Ajinkya Moruskar", position: "Backend Lead", img: ajinkya },
  { name: "Shital Gade", position: "Developer", img: shital },
  { name: "Swapnil Saravade", position: "Developer", img:swapnil },
  { name: "Priyanka Khatmode", position: "Developer", img: priyanka },
  { name: "Bharati Loyare", position: "Developer", img: bharati},
  { name: "Onkar Kulkarni", position: "Developer", img: onkar},
  { name: "Sonali Chaudhari", position: "Developer", img: sonali},
  { name: "Prasad Ghanekar", position: "Tester", img: prasad },
  { name: "Kaustubh Pagar", position: "Business Analyst", img: kaustubh },
  { name: "Vinay Gaikwad", position: "Digital Marketing Lead", img: vinay},


 
];

const OurTeam = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isManual, setIsManual] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Duplicate the team members to create a continuous loop effect
  const members = [...teamMembers, ...teamMembers];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isManual || isHovered) return;

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % teamMembers.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [isManual, isHovered]);

  const handleNext = () => {
    setIsManual(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % teamMembers.length);
  };

  const handlePrev = () => {
    setIsManual(true);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + teamMembers.length) % teamMembers.length);
  };

  return (
    <div className="bg-gradient-to-b from-gray-800 to-black py-12">
      <h2 className="text-4xl font-bold text-center mb-12 text-white">The Brilliant Minds Behind the Project</h2>
      <div className="relative overflow-hidden">
        {isMobile ? (
          // Mobile version - Carousel without animation
          <div className="relative">
            <div className="flex flex-col items-center text-center p-6 bg-gray-900 text-white rounded-xl shadow-lg">
              <img
                src={teamMembers[currentIndex].img}
                alt={teamMembers[currentIndex].name}
                className="w-40 h-40 rounded-full object-cover mb-4 border-4 border-gray-700"
              />
              <h3 className="text-2xl font-semibold text-gray-100">{teamMembers[currentIndex].name}</h3>
              <p className="text-gray-400 mt-2 text-lg">{teamMembers[currentIndex].position}</p>
            </div>

            {/* Carousel Navigation Buttons */}
            <button
              onClick={handlePrev}
              className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full shadow-md hover:bg-gray-600"
            >
              &lt;
            </button>
            <button
              onClick={handleNext}
              className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full shadow-md hover:bg-gray-600"
            >
              &gt;
            </button>
          </div>
        ) : (
          // Desktop version - Animated slide
          <motion.div
            className="flex gap-10 transition-all duration-700 ease-in-out"
            style={{
              transform: `translateX(-${currentIndex * (100 / 4)}%)`,
              transition: "transform 0.7s ease-in-out",
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {members.map((member, index) => (
              <div
                key={index}
                className="bg-gray-900 text-white rounded-xl shadow-lg p-6 flex flex-col items-center text-center hover:scale-105 transform transition-transform duration-300 mx-4"
                style={{
                  minWidth: "25%", // for desktop, show 4 members at once
                }}
              >
                <img
                  src={member.img}
                  alt={member.name}
                  className="w-40 h-40 rounded-full object-cover mb-4 border-4 border-gray-700"
                />
                <h3 className="text-2xl font-semibold text-gray-100">{member.name}</h3>
                <p className="text-gray-400 mt-2 text-lg">{member.position}</p>
              </div>
            ))}
          </motion.div>
        )}

        {/* Navigation Buttons (Visible on Desktop and Mobile) */}
        <button
          onClick={handlePrev}
          className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full shadow-md hover:bg-gray-600"
        >
          &lt;
        </button>
        <button
          onClick={handleNext}
          className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-700 text-white p-2 rounded-full shadow-md hover:bg-gray-600"
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default OurTeam;
